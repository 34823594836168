import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BusinessData, ParticularData } from '../models/users/users-data';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinancingService {

  constructor(private http: HttpClient) { }

  public createFinancing(data: any): Observable<string> {
    const url = `${environment.baseUrl}/financing`;
    return this.http.post<string>(url, data, { responseType: 'text' as 'json' });
  }

  public getFinancing(financingId: string): Observable<any[]> {
    return this.http.get<any[]>(`${environment.baseUrl}/financing/${financingId}`);
  }

  public findByFilter(commercialId: string, searchText: string, size: number, page: number): Observable<any> {
    let params = new HttpParams()
      .set('commercialId', commercialId)
      .set('searchtext', searchText)
      .set('size', size)
      .set('page', page);
  
    return this.http.get<any>(`${environment.baseUrl}/financings`, { params });
  }
  public getFinancingList(commercialId: string, page?: number, size?: number) {
    let params = new HttpParams();
  /*   if (page) { */
      params = params.set('commercialId', commercialId).set('page', page!).set('size', size!)
    /* } else {
      params = params.set('comercialId', comercialId)
    } */
    return this.http.get<any>(`${environment.baseUrl}/financings`, { params });
  }

  public requestSms(customerId: string): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/customer/${customerId}/request-phone-code`, null);
  }

  public validatePhone(customerId: string, code: string): Observable<any> {
    const body = {
      code: code
    }
    return this.http.post<any>(`${environment.baseUrl}/customer/${customerId}/validate-phone`, body);
  }

  public requestContract(customerId: string): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/financing/${customerId}/request-contract`, null , { responseType: 'text' as 'json' })
  }

  public calculateFinancing(body: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}/financing/calculate`, body);
  }


}
